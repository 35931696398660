export default {
    "slug": "teaching-ai-to-help-dad",
    "date": "September 15, 2024",
    "title": "Teaching AI to Help My Dad",
    "summary": "This month, I used my self-hosted AI services to help my dad become more organized at work and articulate better, especially after his stroke earlier this year. By creating a custom AI model tailored to his needs, I was able to improve his workflow and typing speed significantly.",
    "paragraphs": [
      "One of the most rewarding experiences I’ve had with AI so far has been helping my dad use it to improve his workflow at work. My dad has always kept his notes short and to the point, but after his stroke earlier this year, even jotting down a few words became more challenging. Typing was slower, and keeping track of everything felt overwhelming.",
      "This month, I worked with him to create a custom AI model using my self-hosted services. The model helps organize his notes and, more importantly, turns those few words into fully articulated ideas. By inputting his shorthand notes, the AI expands them into clear, professional sentences, making it easier for him to keep track of everything and communicate effectively with colleagues.",
      "It’s been incredible to see how this setup has accelerated his typing and boosted his confidence. Watching him discover how AI can simplify tasks and make life a bit easier has been inspiring. This project is a reminder of how technology can be a powerful tool for personal and professional growth."
    ],
    "tags": [
        { label: "AI", url: "/projects?filter=AI" },
        { label: "Docker", url: "/projects?filter=Docker" }
    ],
    "images": [],
    "links": [
        { label: "Related Projects", url: "/projects?filter=AI" }
    ]
  };
  