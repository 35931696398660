export default {
    "slug": "self-hosting-begins",
    "date": "June 10, 2024",
    "title": "Starting My Self-Hosting Journey",
    "summary": "A reflection on the initial steps taken to self-host a personal website, including the transition from Windows to Debian, configuring Nginx as a reverse proxy, and securing the server with HTTPS.",
    "paragraphs": [
      "Today marks the beginning of my self-hosting adventure! I’ve decided to turn an old Mini PC into a web server to host my personal website. After uninstalling Windows and installing Debian, I spent the day configuring Nginx to act as a reverse proxy and securing everything with HTTPS. It’s incredible to see my website live and being served from my own hardware.",
      "Managing DNS records through Cloudflare was a bit tricky at first, but I’m starting to get the hang of it. This feels like a small step, but it’s opening the door to so many possibilities. I can’t wait to see where this project takes me!"
    ],
    "tags": [
        { label: "AI", url: "/projects?filter=AI" },
        { label: "React", url: "/projects?filter=React" },
        { label: "Docker", url: "/projects?filter=Docker" },
        { label: "Kubernetes", url: "/projects?filter=Kubernetes" }
    ],
    "images": [],
    "links": [
        { label: "Related Projects", url: "/projects" }
    ]
  };
  