export default {
    "slug": "first-work-trip-long-island",
    "date": "November 4, 2024",
    "title": "My First Work Trip: Long Island, New York",
    "summary": "In November, I embarked on my first work trip to Long Island, New York, where I participated in a data center audit. Writing Python scripts to compile audit data and seeing my work applied on-site was a rewarding experience.",
    "paragraphs": [
      "November marked a major milestone in my professional journey—my first work trip to Long Island, New York! This was a hands-on opportunity to apply my skills and see the impact of my contributions in real-time. Leading up to the trip, I wrote Python scripts to compile and process data for a comprehensive audit of the data center’s design, capacity, and inventory.",
      "The experience of being on-site was unlike anything I’ve done before. Walking through the data center and participating in the audit was both challenging and exciting. I was able to address concerns related to design, capacity, and inventory by leveraging the insights generated from my scripts. Seeing how the data directly informed decisions and discussions was incredibly rewarding.",
      "I was able to meet and collaborate with a number of colleagues and managers from different teams, which was a great opportunity to learn from their expertise and gain new perspectives. The trip was a reminder of the importance of teamwork and communication in a professional setting. It was inspiring to see how everyone came together to address challenges and drive progress.",
      "This trip reinforced the importance of combining technical skills with in-person collaboration. It was a great reminder of how impactful preparation and automation can be in solving real-world problems. I’m looking forward to more opportunities like this in the future!"
    ],
    "tags": [{label: "Verizon", url: "/experience"}],
    "images": [],
    "links": [{label: "See My Work Experience", url: "/experience"}]
  };
  